<template>
  <div>
    <br />
    <vs-button id="installBtn" success icon border block>
      <i class="fas fa-plus-circle"></i> Install
      <template #animate>
        <i class="fas fa-plus-circle"></i>
      </template>
    </vs-button>
  </div>
</template>

<script>
export default {
  name: "InstallBtn",
  methods: {},
  data: () => {
    return {};
  }
};
</script>

<style scoped></style>
