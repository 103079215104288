<template>
  <!-- :img="require('@/assets/events_slide3.jpg')" -->
  <div class="home">
    <div class="mainCnt">
      <video v-if="!hover" class="bgVideo" preload autoplay muted loop id="bkgrdVideo">
        <source v-if="hover < 2" :src="require('@/assets/byp.mp4')" type="video/mp4" />
      </video>

      <div class="launcherBlock">
        <div class="welcomeTitle">
          <h2>
            Hola{{ currentUser.username ? " " : null }}
            {{ currentUser ? currentUser.username : " usuario" }},
          </h2>
        </div>
        <div class="blocksTitle">
          <h6>¿Quieres buscar un plan?</h6>
          <h6>¿Quieres crear un plan?</h6>
        </div>
        <div class="blocksCarrousel">
          <CardsCarrouselVue />
        </div>
        <div class="blocksBtns">
          <vs-button color="#f83255" floating @click="redirectPage">
            <i class="fas fa-search"></i>
            BUSCAR
          </vs-button>
          <vs-button color="#f83255" floating @click="redirectPage">
            <i class="fas fa-pen"></i>
            CREAR
          </vs-button>
        </div>
      </div>

      <div class="marketingBlock">
        <div class="blockTitle">
          <h4>Planes recomendados</h4>
          <div class="blocksCarrousel">
            <CardsCarrouselVue type="M" />
          </div>
        </div>
        <div class="blockTitle">
          <h4>Planes más vistos</h4>
          <div class="blocksCarrousel">
            <CardsCarrouselVue type="M" />
          </div>
        </div>
      </div>
    </div>

    <!-- <HomeSlider /> -->
    <CreateEvent :onClose="onClose" :active="this.active" />
  </div>
</template>

<script>
import { noficationsDialog } from "@/common/mixin.js";
import LoginBtn from "@/components/LoginBtn.vue"; // @ is an alias to /src
import InstallBtn from "@/components/InstallBtn.vue";
import HomeSlider from "@/components/HOME/Slider.vue";
import CardsCarrouselVue from "@/components/CARDS/CardsCarrousel.vue";
import CreateEvent from "@/views/CreateEvent.vue";

export default {
  name: "home",
  mixins: [noficationsDialog],
  components: {
    HomeSlider,
    CreateEvent,
    CardsCarrouselVue
  },
  methods: {
    playSounds(type) {
      let audio = document.getElementById("searchAudio");
      if (type < 1) {
        audio = document.getElementById("createAudio");
        this.hover = 1;
      } else {
        this.hover = 2;
      }
      audio.play();
    },
    stopSounds(type) {
      let audio = document.getElementById("searchAudio");
      this.hover = null;
      if (type < 1) {
        audio = document.getElementById("createAudio");
      }
      audio.pause();
      audio.currentTime = 0;
    },

    onClose() {
      this.active = false;
    },

    redirectPage(oEvent) {
      const type = oEvent.currentTarget.innerText;
      const { currentUser } = this.$store.state;
      if (type === "CREAR") {
        if (!currentUser.username) {
          this.notificateLoginRequired("top-center", "warn", LoginBtn);
        } else {
          // this.stopSounds(0);
          this.active = true;
        }
      } else {
        this.$router.push({ name: "Search" });
      }
    },

    openNotification(position, color) {
      const noti = this.$vs.notification({
        color,
        id: "dialogInstall",
        duration: "none",
        position,
        clickClose: true,
        buttonClose: true,
        sticky: true,
        title: "Install BYourPlan in your device",
        content: InstallBtn,
        text:
          "You can install this app without App Store / Play Store, it's more faster, more secure and more EASY!"
      });
    }
  },
  data() {
    const { currentUser } = this.$store.state;
    return {
      hover: null,
      active: false,
      currentUser
    };
  },
  sockets: {
    connect: function() {
      console.log("socket connected");
    },
    messages: function(data) {}
  },
  mounted() {
    let createCard = document.getElementById("create");
    let searchCard = document.getElementById("search");

    if (createCard) {
      createCard = createCard.children[0];
      createCard.style.color = "#f8f9fa";
      createCard.style.background = "#e91e63";
      createCard.style.boxShadow = "4px 2px 15px 5px #5f0c28";
    }

    if (searchCard) {
      searchCard = searchCard.children[0];
      searchCard.style.color = "#17a2b8";
      searchCard.style.background = "rgba(0,0,0,.12)";
      searchCard.style.boxShadow = "4px 2px 15px 5px #0c525e";
    }
  }
};
</script>

<style scoped>
/* FIRST BLOCK */

.bgVideo {
  height: 25%;
  object-fit: cover;
  width: 100%;
}

/* SECOND BLOCK  */

.launcherBlock {
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 2px 2px 12px 0px rgba(31, 16, 19, 0.425);
}

.blockTitle {
  text-align: start;
  margin-bottom: 1rem;
  border-radius: 1rem;
  margin-top: 0.5rem;
  padding: 1rem;
  box-shadow: 1px 8px 20px 0px rgb(0 0 0 / 38%);
}

.welcomeTitle {
  text-align: start;
}
.welcomeTitle > h2 {
  font-weight: bold;
}
.blockTitle > h4 {
  font-weight: bold;
  font-size: 1.25rem;
}

.blocksTitle,
.blocksBtns {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

/* THIRDS BLOCK */

/* .marketingBlock {
  padding: 1rem;
} */

/* OTROS */
</style>
