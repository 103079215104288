<template>
  <div class="sliderCnt">
    <div class="slider">
      <h1>Promos</h1>
      <CardSlider />
    </div>
    <div class="slider">
      <h1>Los más vistos</h1>
      <CardSlider />
    </div>
    <div class="slider">
      <h1>Los más recientes</h1>
      <CardSlider />
    </div>
  </div>
</template>

<script>
import CardSlider from "@/components/CardSlider.vue";
export default {
  name: "homeSlider",
  components: {
    CardSlider
  }
};
</script>

<style scoped>
.slider {
  background-color: #212529;
  color: #c9105b;
}

.sliderCnt:after {
  content: "";
  border-color: #212529 transparent transparent transparent;
  border-style: solid;
  border-width: 25px;
  width: 0px;
  height: 0;
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: rotate(180deg);
}
</style>
