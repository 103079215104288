<template>
  <vs-card-group class="nanoCardGroup">
    <vs-card
      :class="type === 'M' ? 'nanoCard nanoCardM' : 'nanoCard'"
      type="5"
      v-for="card in 6"
      :key="card"
    >
      <!-- <template #title>
        <h3>Evento {{ card }}</h3>
      </template> -->
      <template #img>
        <img :src="require('@/assets/events_slide3.jpg')" alt="" />
      </template>
      <!-- <template #text>
        <p>Descripción {{ card }}</p>
      </template> -->
      <!-- <template #interactions>
        <vs-button danger icon>
          <i class="fas fa-shopping-basket"></i>
        </vs-button>
        <vs-button class="btn-chat" shadow primary>
          <i class="fas fa-users"></i>
          <span class="span">
            54
          </span>
        </vs-button>
      </template> -->
    </vs-card>
  </vs-card-group>
</template>

<script>
export default {
  name: "CardsCarrousel",
  props: ["type"],
  methods: {},
  data: () => {
    return {};
  }
};
</script>

<style>
.nanoCardGroup > .vs-card__group-cards {
  overflow-x: hidden !important;
  padding-top: 0;
}

.nanoCard {
  height: 50px;
  min-width: 30% !important;
  margin: 0.25rem !important;
}

.nanoCardM {
  min-width: 45% !important;
}

.nanoCard > .vs-card {
  margin: 0;
}

.nanoCard > .vs-card > .vs-card__img {
  width: 100%;
}
</style>
