<template>
  <vs-card-group>
    <vs-card type="5" v-for="card in 6" :key="card">
      <template #title>
        <h3>Evento {{ card }}</h3>
      </template>
      <template #img>
        <img :src="require('@/assets/events_slide3.jpg')" alt="" />
      </template>
      <template #text>
        <p>Descripción {{ card }}</p>
      </template>
      <template #interactions>
        <vs-button danger icon>
          <i class="fas fa-shopping-basket"></i>
        </vs-button>
        <vs-button class="btn-chat" shadow primary>
          <i class="fas fa-users"></i>
          <span class="span">
            54
          </span>
        </vs-button>
      </template>
    </vs-card>
  </vs-card-group>
</template>

<script>
export default {
  name: "CardSlider",
  methods: {},
  data: () => {
    return {};
  }
};
</script>
